<template>
  <div>
    <ContentLoader v-if="loading" />
    <div v-else>
      <button
        v-for="(type, code, index) in procedureTypes"
        :key="'proc_type_' + code"
        class="opacity-70 hover:opacity-90 hover:text-primary px-4 py-3 w-full justify-between gap-3 flex items-center group transition duration-150 font-semibold"
        :class="{
          'border-t border-gray-100': index > 0,
          'bg-gray-100 text-gray-400 hover:text-gray-400 focus:outline-none cursor-not-allowed': !allowedProcedureTypes.includes(
            type
          ),
        }"
        :disabled="!allowedProcedureTypes.includes(type)"
        @click="createDraft(type)"
      >
        <div class="flex-1 text-left font-bold">
          {{ $t(`title.procedureTypes.${type}`) }}
        </div>
        <div
          v-if="allowedProcedureTypes.includes(type)"
          class="flex-shrink-0 text-right text-primary px-3 py-2 rounded-lg bg-gray-500 bg-opacity-10 group-hover:bg-primary group-hover:bg-opacity-10 uppercase text-sm font-bold"
        >
          {{ $t('button.createNew') }}
        </div>
      </button>
    </div>
    <div class="text-right mt-5">
      <fw-button type="link-muted" @click.native="$emit('close')">Cancelar</fw-button>
    </div>
  </div>
</template>

<script>
import ContentLoader from '@/components/utilities/ContentLoader'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    ContentLoader,
  },

  data() {
    return {
      procedureTypes: [],
      loading: false,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    allowedProcedureTypes() {
      return this.$store.state.allowedProcedureTypes
    },

    permissionsLoaded() {
      return this.$store.getters.isPermissionsLoaded
    },

    permissions() {
      return this.$store.getters.userPermissions
    },

    canCreate() {
      return this.permissions.isProcedureManager
    },
  },

  beforeMount() {
    this.getProcedureTypes()
  },

  mounted() {
    // Make sure user can see this page
    if (this.permissionsLoaded && !this.canCreate) {
      this.$router.push({ name: 'forbidden' })
    }
  },

  methods: {
    async getProcedureTypes() {
      this.loading = true
      try {
        const result = await this.api.getProcedureTypes()
        console.log('getProcedureTypes ==>', result)
        this.procedureTypes = result['uc']
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },

    async createDraft(procedureType) {
      let key = null
      try {
        const result = await this.api.createProcedure({ draft: true }, procedureType)
        key = result.key
      } catch (error) {
        const errorKey = utils.errors(error).getKey()
        console.log('Error KEY', errorKey)
        this.$buefy.dialog.alert({
          title: this.$t('errorOccurred.default.title'),
          message: this.$t('errorOccurred.default.message', { errorKey: errorKey ? `(${errorKey})` : '' }),
          type: 'is-danger',
          duration: 4000,
        })
      }

      if (key) {
        this.$router.push({ path: `/manage/procedure/${key}/dashboard` })
      }
    },
  },
}
</script>

<i18n>

{
  "en": {
    "title": {
      "newProcedure": "New Procedure",
      "chooseToCreate": "Choose the type of procedure to create",
      "procedureTypes": {
        "teachers": "Teachers",
        "researchers": "Researchers",
        "researchers-dl57": "Researchers DL 57",
        "scholarships": "Scholarships",
        "staff": "Staff"
      }
    },
    "button": {
      "createNew": "Create"
    },
    "errorOccurred": {
      "default": {
        "title": "Ocorreu um erro",
        "message": "Ocorreu um erro não esperado {errorKey}. Por favor, contacte a nossa equipa de suporte."
      }
    }
  },
  "pt": {
    "title": {
      "newProcedure": "Novo Procedimento",
      "chooseToCreate": "Escolha o tipo de procedimento a criar",
      "procedureTypes": {
        "teachers": "Professores",
        "researchers": "Investigadores",
        "researchers-dl57": "Investigadores DL 57",
        "scholarships": "Bolsas",
        "staff": "Pessoal não docente"
      }
    },
    "button": {
      "createNew": "Criar"
    },
    "errorOccurred": {
      "default": {
        "title": "An error has occurred",
        "message": "An unexpected error occurred {errorKey}. Please contact our support team."
      }
    }
  }
}
</i18n>
