<template>
  <section>
    <ContextualSearch
      v-if="showSearch"
      :loading="loading"
      :filter-options="filters"
      :applied-filters="appliedFilters"
      :applied-sort="orderByValue"
      :applied-sort-direction="orderDirection"
      :multifilter="multifilter"
      :start-value="searchInput"
      :start-period="startPeriod"
      :end-period="endPeriod"
      :applied-user="selectedUser"
      :order-by-options="orderBy"
      :show-user-picker="true"
      :show-time-period="true"
      :user-picker-label="$t('user')"
      :time-period-label="$t('openApplicationsDate')"
      :can-close="activeModal === null"
      @sort-order-changed="sortOrderChanged"
      @search="search"
      @open-user-picker-modal="openModal('user_selector')"
      @delete-user="deleteUser()"
    >
      <template #tags>
        <UserTag v-if="filterUserKey && filterUser" class="ml-2" :user="filterUser" @close="deleteUser"></UserTag>
        <FilterTag
          v-for="(filter, f) in appliedFilters"
          :key="'filter_' + f"
          :text="getFilterText(filter)"
          :show-close-button="true"
          @close="deleteFilter(f)"
        ></FilterTag>
        <FilterTag
          v-if="startPeriod != null && endPeriod != null"
          :text="startPeriod + ' - ' + endPeriod"
          :show-close-button="true"
          @close="deleteDates()"
        ></FilterTag>
      </template>
    </ContextualSearch>

    <slot name="stats" />

    <fw-panel
      :title="$t('results')"
      :counter="procedures.length"
      :counter-total="totalResults"
      boxed
      class="my-5"
      custom-class="bg-white p-0"
    >
      <fw-panel-info v-if="!loading && !procedures.length" type="basic" class="text-center my-5 text-gray-500">
        {{ $t('noProcedures') }}
      </fw-panel-info>
      <RecycleScroller
        v-if="procedures.length > 0"
        v-slot="{ item }"
        key-field="key"
        :items="procedures"
        :item-size="50"
        :buffer="50"
      >
        <div class="p-1 border-b border-gray-100">
          <RecordManageProcedure :item="item" @open-procedure="openProcedure(item.key)"></RecordManageProcedure>
        </div>
      </RecycleScroller>
    </fw-panel>

    <BlockPagination
      v-if="totalPages > 1 && !loading"
      :per-page="limit"
      :total="totalResults"
      :total-pages="totalPages"
      :current.sync="page"
      @page-changed="pageChanged"
    />

    <b-modal
      :active="activeModal !== null"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <ChoosePeopleModal
        v-if="activeModal === 'user_selector'"
        :title="$t('selectUser')"
        :instructions="$t('selectUserToFilter')"
        :multiselect="false"
        :selected="currentSelectedUsers"
        :override-user-list="usersList"
        :invite-people="false"
        :endpoint="endpointSearch"
        @selected="selectedPeople"
        @close="closeModal"
      ></ChoosePeopleModal>
    </b-modal>
  </section>
</template>

<script>
/*

  PROCEDURES

  GET /v1/manage/procedures?l=200&p=2
  - filtrar por
      estado: draft, published, running, jury, ended, closed (OR)
      user_key //ir buscar os procedimentos do user (apenas managers ou admins podem ver os outros procedimentos dos outros)
      organization
      type: scholarships | others..

  ORDER BY
  - next_event (usas os meetings)
  - 'publish_start',
  - 'publish_end',
  - 'published_dr_date',
  - 'end_date_complaints',
  - pep_code
  - prefix + code
  - type

RESPONSE
active_limit: 200
active_page: 1
total: 52
total_pages: 1
data: []

*/
import { RecycleScroller } from 'vue-virtual-scroller'
import RecordManageProcedure from '@/components/records/RecordManageProcedure'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag.vue'
import UserTag from '@/fw-modules/fw-core-vue/ui/components/text/UserTag.vue'
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'

import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    BlockPagination,
    RecordManageProcedure,
    ContextualSearch,
    RecycleScroller,
    UserTag,
    FilterTag,
    ChoosePeopleModal,
  },
  props: {
    showFilters: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    multifilter: {
      type: Boolean,
      default: true,
    },
    maxNumberProcedures: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      filterByType: 'all',
      filters: [
        {
          key: 'status',
          label: this.$t('procedureStatus.label'),
          options: [
            {
              key: 'reset',
              label: this.$t('all'),
            },
            {
              key: 'draft',
              label: this.$t('procedureStatus.draft'),
            },
            {
              key: 'published',
              label: this.$t('procedureStatus.published'),
            },
            {
              key: 'jury',
              label: this.$t('procedureStatus.jury'),
            },
            {
              key: 'ended',
              label: this.$t('procedureStatus.ended'),
            },
            {
              key: 'closed',
              label: this.$t('procedureStatus.closed'),
            },
          ],
        },
        {
          key: 'type',
          label: this.$t('procedureTypes.label'),
          options: [
            {
              key: 'reset',
              label: this.$t('all'),
            },
            {
              key: 'teachers',
              label: this.$t('procedureTypes.teachers'),
            },
            {
              key: 'researchers',
              label: this.$t('procedureTypes.researchers'),
            },
            {
              key: 'researchers-dl57',
              label: this.$t('procedureTypes.researchers-dl57'),
            },
            {
              key: 'scholarships',
              label: this.$t('procedureTypes.scholarships'),
            },
            {
              key: 'staff',
              label: this.$t('procedureTypes.staff'),
            },
          ],
        },
      ],

      orderBy: [
        {
          key: 'code',
          label: this.$t('orderBy.procedureCode'),
          type: 'string',
        },
        {
          key: 'status',
          label: this.$t('orderBy.procedureStatus'),
          type: 'string',
        },
        {
          key: 'created_date',
          label: this.$t('orderBy.createdDate'),
          type: 'date',
        },
        {
          id: 'publish_start',
          label: this.$t('orderBy.publishStart'),
          type: 'date',
        },
        {
          key: 'publish_end',
          label: this.$t('orderBy.publishEnd'),
          type: 'date',
        },
      ],
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 25,
      procedures: [],
      loading: true,
      searchInput: '',
      orderByValue: '',
      orderDirection: 'none',
      appliedFilters: [],
      startPeriod: null,
      endPeriod: null,
      filterUserKey: null,
      filterUser: null,
      selectedUser: null,
      activeModal: null,
      usersList: null,
    }
  },
  computed: {
    api() {
      return this.$store.state.api.base
    },
    currentSelectedUsers() {
      return this.filterUser != null ? [this.filterUser] : []
    },
  },
  mounted() {
    utils.sleep(100).then(async () => {
      this.getUrlParams()
      await this.getUsers()
      await this.getProcedures()
    })
  },
  beforeMount() {},
  methods: {
    async endpointSearch(params) {
      return await this.api.getUsers(params)
    },
    openProcedure(key) {
      this.saveCurrentScroll()
      this.$router.push(`/manage/procedure/${key}/dashboard`)
    },
    async scrollToSavedPos() {
      let path = this.$router.currentRoute.path
      if (this.$store.state.tmbScrollPos[path] && this.$store.state.tmbScrollPos[path] > 0) {
        await utils.sleep(500)
        document.querySelectorAll('main')[0].scrollTo({ top: this.$store.state.tmbScrollPos[path] })
        this.saveScrollPos(null)
      }
    },
    saveCurrentScroll() {
      this.saveScrollPos(document.querySelectorAll('main')[0].scrollTop)
    },
    saveScrollPos(value) {
      let path = this.$router.currentRoute.path
      if (value == null) {
        this.$store.commit('deleteCurrentScrollPos', path)
      } else {
        this.$store.commit('saveCurrentScrollPos', { key: path, pos: value })
      }
    },
    selectedPeople(value) {
      if (value.length > 0) {
        this.selectedUser = value[0]
      } else {
        this.selectedUser = null
      }
    },
    async closeModal() {
      await utils.sleep(100)
      this.activeModal = null
    },
    async getUsers() {
      if (this.filterUserKey != null) {
        try {
          const user = await this.api.getUserDetails(this.filterUserKey)
          this.selectedUser = user
          this.filterUser = user

          console.log('Hi user', user)
        } catch (error) {
          console.error(error)
        }
      }
    },
    openModal(type) {
      this.activeModal = type
    },
    deleteUser() {
      this.selectedUser = null
      this.filterUserKey = null
      this.filterUser = null
      this.setUrlParams()
      this.getProcedures()
    },
    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.setUrlParams()
      this.getProcedures()
    },
    deleteDates() {
      this.startPeriod = null
      this.endPeriod = null
      this.setUrlParams()
      this.getProcedures()
    },
    getFilterText(key) {
      let split = key.split(':')
      for (let i = 0; i < this.filters.length; i++) {
        if (this.filters[i].key == split[0]) {
          for (let j = 0; j < this.filters[i].options.length; j++) {
            if (this.filters[i].options[j].key == split[1]) {
              return this.filters[i].options[j].label
            }
          }
        }
      }
      return ''
    },
    search(data) {
      this.appliedFilters = JSON.parse(JSON.stringify(data.filters))
      this.searchInput = data.term

      if (data.orderBy != null) {
        this.orderByValue = data.orderBy
        this.orderDirection = data.orderDirection
      }

      this.filterUser = data.user
      this.filterUserKey = data.user != null ? data.user.key : null

      this.startPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[0]) : null
      this.endPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[1]) : null

      this.$emit('searching')

      this.setUrlParams()
      this.getProcedures()
    },
    getUrlParams() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q
      }
      if (this.$route.query.f) {
        this.appliedFilters = this.$route.query.filters.split(',')
      }
      if (this.$route.query.s) {
        this.orderByValue = this.$route.query.s
        this.orderDirection =
          this.$route.query.o == 'ASC' || this.$route.query.o == 'DESC' ? this.$route.query.o : 'none'
      }
      if (this.$route.query.start) {
        this.startPeriod = this.$route.query.start
      }

      if (this.$route.query.end) {
        this.endPeriod = this.$route.query.end
      }

      if (this.$route.query.user) {
        this.filterUserKey = this.$route.query.user
      }
      if (this.$route.query.p) {
        this.page = this.$route.query.p
      }
    },
    setUrlParams() {
      let query = {}
      if (this.searchInput.length > 0) {
        query['q'] = this.searchInput
      }
      if (this.appliedFilters.length > 0) {
        query['f'] = this.appliedFilters.join(',')
      }
      if (this.orderByValue.length > 0) {
        query['s'] = this.orderByValue
        query['o'] = this.orderDirection
      }
      if (this.startPeriod != null && this.endPeriod != null) {
        query['start'] = this.startPeriod
        query['end'] = this.endPeriod
      }

      if (this.filterUserKey != null) {
        query['user'] = this.filterUserKey
      }

      if (this.page > 1) {
        query['p'] = parseInt(this.page)
      }
      this.$router.push({ query: query })
    },
    sortOrderChanged(newsort) {
      if (newsort != null && newsort.key != null) {
        this.orderByValue = newsort.key.key
        this.orderDirection = newsort.direction
      }
      this.setUrlParams()
      this.getProcedures()
    },

    async getProcedures() {
      this.loading = true
      let maxNumber = this.maxNumberProcedures != null ? this.maxNumberProcedures : this.limit
      let query = { limit: maxNumber }
      if (this.orderByValue.length > 0) {
        query['sort'] = this.orderByValue
        query['direction'] = this.orderDirection.toLowerCase()
      }
      if (this.page > 1) {
        query['page'] = this.page
      }
      if (this.appliedFilters.length > 0) {
        query['filters'] = this.appliedFilters.join(',')
      }
      if (this.searchInput.length > 0) {
        query['q'] = this.searchInput
      }
      if (this.filterUserKey !== '') {
        query['user'] = this.filterUserKey
      }

      if (this.startPeriod && this.endPeriod) {
        query['start_date'] = this.parseDatesForPayload(this.startPeriod)
        query['end_date'] = this.parseDatesForPayload(this.endPeriod)
      }

      try {
        const result = await this.api.getProceduresWithPermissions(query)
        this.totalResults = result.total
        this.totalPages = result.total_pages
        this.page = result.active_page
        this.procedures = result.data
      } catch (error) {
        console.error(error)
      }

      this.loading = false
      //Scroll only after getting the results!
      this.scrollToSavedPos()
    },
    parseDatesForPayload(formatedDate) {
      if (formatedDate) {
        return Dates.from(formatedDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      return formatedDate
    },
    pageChanged(page) {
      if (page) this.page = page
      this.setUrlParams()
      this.getProcedures()
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "openApplicationsDate": "Data de abertura das candidaturas",
    "results": "Resultados",
    "noProcedures": "Sem procedimentos para apresentar.",
    "loadMoreData": "Ver mais resultados",
    "selectUser": "Selecione um utilizador",
    "user": "utilizador",
    "selectUserToFilter": "Selecione um utilizador para filtrar as atividades",
    "procedureStatus": {
      "label": "Estado(s) de procedimento(s)",
      "draft": "Rascunho",
      "jury": "Júri",
      "running": "Iniciado",
      "ended": "Audiência(s) de interessados",
      "closed": "Encerrado",
      "published": "Publicado"
    },
    "procedureTypes": {
      "label": "Tipo(s) de procedimento(s)",
      "teachers": "Professores",
      "researchers": "Investigadores",
      "researchers-dl57": "Investigadores DL 57",
      "scholarships": "Bolsas",
      "staff": "Pessoal não docente"
    },
    "all": "Todos",
    "orderBy": {
      "procedureCode": "Código do procedimento",
      "procedureStatus": "Estado do procedimento",
      "createdDate": "Data de criação",
      "publishStart": "Data de abertura das candidaturas",
      "publishEnd": "Data de fecho das candidaturas"
    }
  },
  "en": {
    "openApplicationsDate": "Opening date for applications",
    "results": "Results",
    "noProcedures": "No procedures to present.",
    "loadMoreData": "Load more data",
    "selectUser": "Select a user",
    "user": "User",
    "selectUserToFilter": "Select a user to filter activities",
    "procedureStatus": {
      "label": "Procedure status",
      "draft": "Draft",
      "jury": "Jury",
      "running": "Running",
      "ended": "Stakeholder Hearing",
      "closed": "Ended",
      "published": "Published"
    },
    "procedureTypes": {
      "label": "Procedure type",
      "teachers": "Teachers",
      "researchers": "Researchers",
      "researchers-dl57": "Researchers DL 57",
      "scholarships": "Scholarships",
      "staff": "Staff"
    },
    "all": "All",
    "orderBy": {
      "procedureCode": "Procedure code",
      "procedureStatus": "Procedure status",
      "createdDate": "Creation date",
      "publishStart": "Opening date for applications",
      "publishEnd": "Closing date for applications"
    }
  }
}
</i18n>
