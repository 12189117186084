<template>
  <fw-layout>
    <template #main-sidebar>
      <SidebarManage />
    </template>
    <template #main-content>
      <fw-panel :title="$t('procedures')" featured>
        <template v-if="userRoles.isProcedureManager" #toolbar>
          <span class="flex flex-1 justify-end">
            <fw-button type="transparent-primary" @click.native="showModalNewProcedure = true">{{
              $t('add')
            }}</fw-button></span
          >
        </template>
        <ManageProcedureList :show-filters="false" @searching="hideStatsOnSearching = true">
          <template v-if="showStats && !hideStatsOnSearching" #stats>
            <div class="flex gap-10 my-5 ml-5 w-">
              <PanelStats :title="$t('numbers')" :stats="stats.procedures" :loading="loadingStats" />
              <PanelStats
                v-if="stats.applications && !loadingStats"
                :title="$t('applications')"
                :stats="stats.applications"
                :stats-warning="false"
              />
            </div>
          </template>
        </ManageProcedureList>
      </fw-panel>
    </template>
    <template #modals>
      <fw-modal
        :title="$t('createProcedure')"
        :description="$t('chooseprocedureType')"
        :active="showModalNewProcedure"
        @close="showModalNewProcedure = false"
      >
        <modal-manage-new-procedure @close="showModalNewProcedure = false" />
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import SidebarManage from '@/components/sidebars/SidebarManage'
import ManageProcedureList from '@/components/panels/ManageProcedureList'
import PanelStats from '@/fw-modules/fw-core-vue/ui/components/panels/PanelStats'
import ModalManageNewProcedure from '@/components/modals/ModalManageNewProcedure'

export default {
  name: 'ManageProcedures',
  components: {
    ManageProcedureList,
    ModalManageNewProcedure,
    SidebarManage,
    PanelStats,
  },

  data() {
    return {
      stats: {},
      hideStatsOnSearching: false,
      loadingStats: true,
      showModalNewProcedure: false,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    userRoles() {
      return this.$store.getters.userPermissions
    },
    showStats() {
      return this.userRoles.isAdmin || this.userRoles.isProcedureManager || this.userRoles.isManager
    },
    isManager() {
      return this.userRoles.isManager
    },
    permissionsLoaded() {
      return this.$store.getters.isPermissionsLoaded
    },
  },

  mounted() {
    this.loadStats()
  },

  methods: {
    async loadStats() {
      this.loadingStats = true

      let data

      try {
        data = await this.api.getProceduresStats()
      } catch (error) {
        console.error('Error loadStats: ', error)
      }

      this.stats = {
        // For manager, procedure-manager and admin
        procedures: [
          {
            label: '',
            value: data.procedure_stats?.draft || 0,
            valueSuffix: null,
            description: this.$t('stats.procedures.draft'),
          },
          {
            label: '',
            value: data.procedure_stats?.published || 0,
            valueSuffix: null,
            description: this.$t('stats.procedures.published'),
          },
          {
            label: '',
            value: data.procedure_stats?.jury || 0,
            valueSuffix: null,
            description: this.$t('stats.procedures.jury'),
          },
          {
            label: '',
            value: data.procedure_stats?.ended || 0,
            valueSuffix: null,
            description: this.$t('stats.procedures.ended'),
          },
          {
            label: '',
            value: data.procedure_stats?.closed || 0,
            valueSuffix: null,
            description: this.$t('stats.procedures.closed'),
          },
        ],
      }

      if (data.applications) {
        // Only manager and admin permissions will have this
        this.stats['applications'] = [
          {
            label: '',
            value: data.applications?.total || 0,
            valueSuffix: null,
            description: this.$t('stats.total'),
          },
          {
            label: '',
            value: data.applications?.submitted || 0,
            valueSuffix: null,
            description: this.$t('stats.applications.submitted'),
          },
          {
            label: '',
            value: data.applications?.cancelled || 0,
            valueSuffix: null,
            description: this.$t('stats.applications.cancelled'),
          },
        ]
      }

      this.loadingStats = false
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "numbers": "Números",
    "procedures": "Procedimentos",
    "applications": "Candidaturas",
    "add": "Adicionar",
    "createProcedure": "Criar procedimento",
    "chooseprocedureType": "Escolha um tipo de procedimento:",
    "stats": {
      "total": "Total",
      "procedures": {
        "draft": "em rascunho",
        "published": "publicados",
        "jury": "em curso",
        "ended": "avaliados",
        "closed": "terminados"
      },
      "applications": {
        "submitted": "Submetidas",
        "cancelled": "Canceladas"
      }
    }
  },
  "en": {
    "numbers": "Numbers",
    "procedures": "Procedures",
    "applications": "Applications",
    "add": "Add",
    "createProcedure": "Criar procedimento",
    "chooseprocedureType": "Choose a type of procedure:",
    "stats": {
      "total": "Total",
      "procedures": {
        "draft": "in draft",
        "published": "published",
        "jury": "in progress",
        "ended": "assessed",
        "closed": "finished"
      },
      "applications": {
        "submitted": "Submitted",
        "cancelled": "Submitted"
      }
    }
  }
}
</i18n>
